@import './common/styles/beautiful-react-diagram.css';
@import './common/styles/fontfamily.css';

body {
  margin: 0;
  padding: 0;
  /* font-family: sans-serif; */
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.rootContainer {
  height: '100vh';
  width: '100vw';
}
.header {
  height: 7vh;
  width: '100%';
}
.sidebar {
  max-width: '240px';
}
.componentContainer {
  height: '93vh';
  width: '';
}
.yAxisTitle {
  position: absolute;
  top: 0;
}
.heatmapXAxisLabel {
  position: fixed;
}
.amcharts-Container {
  max-height: 70%;
  overflow: auto;
}

/** To hide up down arrows from number field*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/*To remove the outline focus from menu ul element*/
ul:focus {
  outline: none !important;
}

script {
  display: none;
}

/* product tour common styles between themes */
#driver-popover-content {
  color: #fff;
  max-width: 350px;
  min-width: 350px;
}

.driver-popover .driver-popover-footer button:hover {
  text-decoration: underline;
  background-color: #f0f0f0;
}

.driver-popover .driver-popover-title,
.driver-popover .driver-popover-description {
  color: #fff;
}

.driver-popover .driver-popover-description {
  padding-block: 15px;
}

.driver-popover .driver-popover-title {
  font-size: 20px;
  gap: 15px !important;
}

.driver-popover .driver-popover-footer {
  gap: 7px;
}

.driver-popover .driver-popover-navigation-btns {
  justify-content: space-between;
  gap: 3px;
}

.driver-popover .driver-popover-close-btn {
  color: #9b9b9b;
}

/* product tour primary theme styles */
.driver-popover.product-tour-modal-dark-blue {
  background-color: #143975;
}

.driver-popover.product-tour-modal-dark-blue button {
  flex: 1;
  text-align: center;
  background-color: #ffffff;
  text-shadow: none;
  font-size: 14px;
  padding: 5px 8px;
  border-radius: 6px;
  color: #143975;
  border: 2px solid #143975;
}

.driver-popover.product-tour-modal-dark-blue .driver-popover-progress-text {
  white-space: nowrap;
  color: #b5b5b5;
}

.driver-popover.product-tour-modal-dark-blue .driver-popover-close-btn:hover {
  color: #143975;
}

.driver-popover.product-tour-modal-dark-blue .driver-popover-arrow-side-left.driver-popover-arrow {
  border-left-color: #143975;
}

.driver-popover.product-tour-modal-dark-blue .driver-popover-arrow-side-right.driver-popover-arrow {
  border-right-color: #143975;
}

.driver-popover.product-tour-modal-dark-blue .driver-popover-arrow-side-top.driver-popover-arrow {
  border-top-color: #143975;
}

.driver-popover.product-tour-modal-dark-blue .driver-popover-arrow-side-bottom.driver-popover-arrow {
  border-bottom-color: #143975;
}

/* display hidden components for tutorial purposes */
:not(body):has(> .driver-active-element#scenario_diagram_content_node, .driver-active-element#scenario_canvas_diagram_node, #model_screen_aspects_header_heatmap_button.driver-active-element) {
  overflow: visible !important;
}

.driver-active-element#aggregated_note_header_menu_button > svg,
#aggregated_note_anchor.driver-active-element svg,
.driver-active-element#value_chain_note_header_menu_button > svg,
#value_chain_note_anchor.driver-active-element svg,
#value_chain_note_expand.driver-active-element {
  visibility: visible !important;
}

#aggregated_note_anchors.driver-active-element > div > div,
#aggregated_note_anchors:has(div > div > div > div > .driver-active-element) > div > div {
  height: auto !important;
  visibility: visible;
}

/* product tour sky blue theme styles */
.driver-popover.product-tour-modal-sky-blue {
  background-color: #47a1f7;
}

.driver-popover.product-tour-modal-sky-blue button {
  flex: 1;
  text-align: center;
  background-color: #ffffff;
  text-shadow: none;
  font-size: 14px;
  padding: 5px 8px;
  border-radius: 6px;
  color: #47a1f7;
  border: 2px solid #47a1f7;
}

.driver-popover.product-tour-modal-sky-blue .driver-popover-progress-text {
  white-space: nowrap;
  color: #555;
}

.driver-popover.product-tour-modal-sky-blue .driver-popover-close-btn:hover {
  color: #47a1f7;
}

.driver-popover.product-tour-modal-sky-blue .driver-popover-arrow-side-left.driver-popover-arrow {
  border-left-color: #47a1f7;
}

.driver-popover.product-tour-modal-sky-blue .driver-popover-arrow-side-right.driver-popover-arrow {
  border-right-color: #47a1f7;
}

.driver-popover.product-tour-modal-sky-blue .driver-popover-arrow-side-top.driver-popover-arrow {
  border-top-color: #47a1f7;
}

.driver-popover.product-tour-modal-sky-blue .driver-popover-arrow-side-bottom.driver-popover-arrow {
  border-bottom-color: #47a1f7;
}
