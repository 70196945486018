.bi.bi-diagram {
  box-shadow: none !important;
  background-color: white !important;
  border: none !important;
}
.bi.bi-diagram svg {
  pointer-events: none;
}
.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link .bi-link-path {
  stroke: unset !important;
  stroke-width: unset !important;
}
.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link .bi-link-ghost {
  z-index: 1000 !important;
}
.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link .bi-link-ghost *:hover {
  background: white;
  color: #143975;
}
.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link.readonly-link .bi-link-path {
  stroke: unset !important;
  stroke-width: unset !important;
  stroke-dasharray: unset !important;
}

.bi-diagram-link-label {
  pointer-events: all;
  background-color: transparent !important;
}
